import React from "react";
import { Link } from "gatsby";

import Icon from "../custom-widgets/icon";

const ServiceNavigation = ({ navData, className }) => {
  return (
    <div className={className}>
      <ul className="list-unstyled">
        {navData.map(({ name, shortName, id }, index) => {
          return (
            <li key={index}>
              <Link to={`#${id}-service-anchor`} id={`${id}-service-anchor-link`} className="text-decoration-none">
                <Icon name="arrow-down" class="mr-2 mt-1" />
                <span className="d-inline" dangerouslySetInnerHTML={{ __html: name }} />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ServiceNavigation;
ServiceNavigation.defaultProps = {
  // Same names as in BusinessServicesCards.js
  navData: [
    {
      name: "Payment Processing Services",
      id: "payment-processing-services"
    },
    {
      name: "HR and Payroll Services",
      id: "hr-and-payroll-services"
    },
    {
      name: "Point of Sale and Inventory Systems",
      id: "point-of-sale-and-inventory-systems"
    },
    {
      name: "Customer Engagement",
      id: "customer-engagement"
    },
    {
      name: "CollectEarly&trade;",
      id: "collectearly"
    },
    {
      name: "Cash Flow Management",
      id: "cash-flow-management"
    },
    {
      name: "Accounting Automation",
      id: "accounting-automation"
    },
    {
      name: "WaFd Bank Work Perks",
      id: "wafd-bank-work-perks"
    },
  ],
  className: ""
};
