import React from "react";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import BusinessServicesCards from "../../../components/business-banking/BusinessServicesCards";
import ServiceNavigation from "../../../components/business-banking/ServiceNavigation";

const BusinessServices = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Business Services"
    }
  ];

  const SEOData = {
    title: "Business Services for Small Business",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",

        content: "Business Services for Small Business"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Are you in need of business services for your small business? Learn about WaFd Bank's Heartland online business services, payment processing, payroll & more."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-merchant-services-02242023.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container adjust-padding-bottom">
        <h1>Business Services</h1>
        <div className="row">
          <div className="col-md-6">
            <h4>
              WaFd Bank is here to help you manage and grow your business. Keeping up with day-to-day operations and
              providing exceptional customer experiences is crucial to long term success.
            </h4>
            <h4>
              We've teamed up with Heartland<sup>&reg;</sup>, to offer you innovative payment and point of sale
              solutions to help you run your business better. We'll help you streamline operations, increase
              productivity and make payments easy so customers can't resist buying from you.
            </h4>
          </div>
          <div className="col-md-6">
            <ServiceNavigation />
          </div>
        </div>
      </section>
      <section className="container adjust-padding-bottom">
        <BusinessServicesCards />
      </section>
      <section id="disclaimer-section" className="container text-sm text-gray-60">
        <p>
          *Up to $5,000 off loan origination fees, including credit report, flood certification, and tax monitoring
          fees. This offer is available on owner-occupied, primary residence, purchase and refinance transactions, and
          custom construction loans. $5,000 off loan origination fee requires automatic mortgage payment via EZ-Pay from
          a WaFd checking account, payroll direct deposit, one or more online Bill Pay and monthly reserves. Financing
          for second homes, investment properties and lot loans are not eligible for discounts. All loans are subject to
          credit approval.
        </p>
        <p className="mb-0">
          **Requires direct deposit and EZ Pay automatic payment option from your WaFd account. 6 months fixed
          introductory rate with a 1.5% discount; rate is variable thereafter.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default BusinessServices;

